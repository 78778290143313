var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  $(document).on('product.launchPalette', '.js-product', function (e, prodId, skuBaseId) {
    prodcat.ui.paletteOverlay.launch(prodId, skuBaseId);
  });

  $(document).on('product.hidePalette', '.js-product', function (e, prodId, skuBaseId) {
    $('.js-palette-mobile-container').empty();
    $('.js-palette-launch').html(site.translations.product.shade_detail);
    $('.js-product__details').show();
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $paletteBtn = $('.js-palette-launch');

    if ($paletteBtn.length < 1) {
      return null;
    }
    var sku = prodcat.data.getSku(skuBaseId);
    var prodId = sku.PRODUCT_ID;

    $paletteBtn.data('sku-base-id', skuBaseId);
    $paletteBtn.data('product-id', prodId);
  });

  $(document).on('click', '.js-palette-launch', function (e) {
    e.preventDefault();
    var $paletteLink = $(this);
    var $productEl = prodcat.ui.getProductEl($paletteLink);
    var prodId = $paletteLink.data('product-id');
    var skuBaseId;

    if (prodcat.data.isPaletteMultiSku(prodId)) {
      skuBaseId = $paletteLink.data('sku-base-id');
    }
    if ($('.js-palette-mobile-container .js-product-palette').length > 0) {
      $productEl.trigger('product.hidePalette', [prodId, skuBaseId]);
      $(this).removeClass('hide_select_grey');
      if (Unison.fetch.now().name == 'small') {
        var $galleryTop;
        var $parent = $(this).parents('.spp-mobile-page');

        if ($('.js-product', $parent).length) { // On SPP
          $galleryTop = $('.js-product', $parent).offset().top - 50;
        } else {
          $galleryTop = $parent.offset().top - 50;
        }
        if (!isNaN($galleryTop)) {
          $('html, body').stop().animate({ scrollTop: $galleryTop }, 500);
        }
      }
    } else {
      $productEl.trigger('product.launchPalette', [prodId, skuBaseId]);
      $(this).addClass('hide_select_grey');
      // If mobile, scroll the page so smoosh image is in view.
      if (Unison.fetch.now().name == 'small') {
        var $galleryTop;
        var $parent = $(this).parents('.js-product');

        if ($('.palette_wrapper', $parent).length) { // On SPP
          $galleryTop = $('.palette_wrapper', $parent).offset().top - 50;
        } else {
          $galleryTop = $parent.offset().top - 50;
        }

        if (!isNaN($galleryTop)) {
          $('html, body').stop().animate({ scrollTop: $galleryTop }, 500);
        }
      }
    }
  });

  $(document).on('mouseover', '.js-palette-swatch', function (e) {
    var $swatch = $(this);
    var index = $swatch.data('index');
    var shadeName = $swatch.data('shade-name');
    var shadeDesc = $swatch.data('shade-desc');
    var $productEl = prodcat.ui.getProductEl($swatch);

    $productEl.trigger('product.shadeDisplay', {
      index: index,
      shadeName: shadeName,
      shadeDesc: shadeDesc
    });
  });

  $(document).on('click', '.js-palette-swatch', function (e) {
    e.preventDefault();
    prodcat.ui.paletteOverlay.selectSwatch($(this));
  });

  $(document).on('product.shadeDisplay', '.js-product', function (e, shadeData) {
    var $productEle = $(this);

    if (!$productEle.hasClass('js-product-palette')) {
      return null;
    }

    var $name = $('.js-palette-sku-shade-name', $productEle);

    $name.html(shadeData['shadeName']);
    var $desc = $('.js-palette-sku-shade-description', $productEle);

    $desc.html(shadeData['shadeDesc']);

    var $img = $('.js-product-palette__photo--smoosh', $(this));

    if ($img.length < 1) {
      return null;
    }
    var oldSrc = $img.attr('src');
    var newSrc = oldSrc.replace(/_\d\./, '_' + shadeData['index'] + '.');

    $img.attr('src', newSrc);
  });

  prodcat.ui.paletteOverlay = {
    launch: function (prodId, skuBaseId) {
      var prodData = prodcat.data.getProduct(prodId);

      prodData = this.parseData(prodData, skuBaseId);

      var isMobile = Unison.fetch.now().name === 'small';
      var templateName = 'product_palette_overlay_v1_uk';
      var html = site.template.get({
        name: templateName,
        data: prodData
      });

      html = $.parseHTML(html);
      $('.product-palette__details-group', html).first().addClass('first');
      if (isMobile) {
        $('.js-palette-mobile-container').html(html);
        $('.js-palette-launch').html(site.translations.product.hide_shade_detail);
        $('.js-product__details').hide();
      } else {
        generic.overlay.launch({ html: html });
      }
      // update add-to-cart, inv status, favorites btn with correct SKU ID
      var $paletteControls = $('.js-inv-status-list, .js-add-to-favorites, .js-add-to-cart', '.js-product-palette');

      $paletteControls.data('sku-base-id', skuBaseId);
      $paletteControls.data('product-id', prodId);

      var $firstLink = $('.js-swatch-list-item-link', '.js-product-palette').first();

      this.selectSwatch($firstLink);
    },
    selectSwatch: function ($swatchLink) {
      var $links = $('.js-swatch-list-item-link', '.js-product-palette');

      $links.removeClass('selected');
      $swatchLink.addClass('selected');
    },
    parseData: function (prodData, skuBaseId) {
      // split comma-separated fields into arrays, left- & right-trimming values
      var parseField = function (fieldName, skuData) {
        var parsedVals = [];

        if (!_.isString(skuData[fieldName])) {
          return [];
        }
        parsedVals = skuData[fieldName].split(',');
        parsedVals = _.map(parsedVals, function (val) {
          val = val.replace(/^\s+/, '');
          val = val.replace(/\s+$/, '');

          return val;
        });

        return parsedVals;
      };

      prodData.selectedSku = prodData.skus[0];
      if (!!skuBaseId) {
        prodData.selectedSku = _.find(prodData.skus, function (sku, idx) {
          return sku.SKU_BASE_ID == skuBaseId;
        });
      }

      // prodData.skus[0]['SMOOSH_DESIGN'] = "Eye Shadow|1, Eye Shadow|2, Eyeliner, Mascara";
      var shadeNames = parseField('SHADENAME', prodData.selectedSku);
      var hexVals = parseField('HEX_VALUE_STRING', prodData.selectedSku);
      var shadeDescs = parseField('SHADE_DESCRIPTION', prodData.selectedSku);
      var groupNames = parseField('SMOOSH_DESIGN', prodData.selectedSku);
      var paletteShades = [];

      // iterate over shade names
      shadeNames = _.each(shadeNames, function (shadeName, idx) {
        // test group names for sub-group indicators |1 |2 etc.
        var groupName = groupNames[idx];
        var subGroupIndex = 0;

        if (/\|/.test(groupName)) {
          var matches = groupName.match(/(^[^\|]+)\|(\d+)/);

          if (!!matches) {
            // remove sub-group characters from group name
            groupNames[idx] = matches[1];
            if (!!matches[2]) {
              // set sub-group for this shade
              subGroupIndex = matches[2] - 1;
              if (isNaN(subGroupIndex)) {
                subGroupIndex = 0;
              }
            }
          }
        }
        // collect data for this shade
        paletteShades[idx] = {
          index: idx,
          shadeName: shadeName || '',
          hexVal: hexVals[idx] || '',
          shadeDesc: shadeDescs[idx] || '',
          groupName: groupNames[idx] || '',
          subGroupIndex: subGroupIndex,
          SKU_BASE_ID: prodData.selectedSku.SKU_BASE_ID
        };
      }); // end iterate

      // get unique list of group names
      groupNames = _.uniq(groupNames);
      // set up top-level bucket for grouped shades
      prodData.paletteShadeGroups = [];

      // iterate through group names 
      _.each(groupNames, function (groupName) {
        // create skeleton of group data
        prodData.paletteShadeGroups.push({
          groupName: groupName,
          shadeSubGroups: [
            {
              shades: []
            }
          ]
        });
      });

      // iterate over shade objects
      _.each(paletteShades, function (shade) {
        var myGroup = _.find(prodData.paletteShadeGroups, function (grp) {
          return grp.groupName == shade.groupName;
        });

        // create sub-group array if it does not yet exist
        if (typeof myGroup.shadeSubGroups[shade.subGroupIndex] === 'undefined') {
          myGroup.shadeSubGroups[shade.subGroupIndex] = { shades: [] };
        }
        // add shade to sub-group array
        myGroup.shadeSubGroups[shade.subGroupIndex]['shades'].push(shade);
      });

      return prodData;
    } // end parseData
  }; // prodcat.ui.paletteOverlay

  $('.show_select').on('click', function () {
    $('.shaded_details').toggle();
    $('.hide_select').toggle();

    // Slick slider in overlay is hidden unless we explicitly get it to reveal it
    // Use current slide index so if a user changes the image, closes the overlay
    // and reopens it, the changed image is viewable
    if ($('.slick-slider.js-product-gallery__main', '.product__details').length) {
      var curr_slide_index = $('.js-product-gallery__main', '.product__details').slickCurrentSlide();

      $('.js-product-gallery__main', '.product__details').slickGoTo(curr_slide_index);
    }

    $('.show_select').toggle();
    // If mobile, scroll the page so smoosh image is in view.
    if (Unison.fetch.now().name == 'small') {
      var $galleryTop;
      var $parent = $(this).parents('.js-product');

      if ($('.shaded_details', $parent).length) { // On SPP
        $galleryTop = $('.shaded_details', $parent).offset().top - 50;
      } else {
        $galleryTop = $parent.offset().top - 50;
      }

      if (!isNaN($galleryTop)) {
        $('html, body').stop().animate({ scrollTop: $galleryTop }, 500);
      }
    }
  });

  $('.hide_select').on('click', function () {
    $('.shaded_details').toggle();
    $('.show_select').toggle();
    $('.hide_select').toggle();
    if (Unison.fetch.now().name == 'small') {
      var $galleryTop;
      var $parent = $(this).parents('.spp-mobile-page');

      if ($('.js-product', $parent).length) { // On SPP
        $galleryTop = $('.js-product', $parent).offset().top - 50;
      } else {
        $galleryTop = $parent.offset().top - 50;
      }
      if (!isNaN($galleryTop)) {
        $('html, body').stop().animate({ scrollTop: $galleryTop }, 500);
      }
    }
  });
})(jQuery);
